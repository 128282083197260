import React from "react";

function Ingredient({ ingredient }) {

    return (
        <div>
            <p>{ingredient}</p>
        </div>
    )
}
export default Ingredient;